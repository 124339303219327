/**
 * @fileoverview gRPC-Web generated client stub for devplay.coupon.internals
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.29.1
// source: devplay/coupon/internals/issue.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var devplay_coupon_internals_coupon_resources_pb = require('../../../devplay/coupon/internals/coupon_resources_pb.js')
const proto = {};
proto.devplay = {};
proto.devplay.coupon = {};
proto.devplay.coupon.internals = require('./issue_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.devplay.coupon.internals.IssueInternalServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.devplay.coupon.internals.IssueInternalServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devplay.coupon.internals.CreateIssueRequest,
 *   !proto.devplay.coupon.internals.Issue>}
 */
const methodDescriptor_IssueInternalService_CreateIssue = new grpc.web.MethodDescriptor(
  '/devplay.coupon.internals.IssueInternalService/CreateIssue',
  grpc.web.MethodType.UNARY,
  proto.devplay.coupon.internals.CreateIssueRequest,
  devplay_coupon_internals_coupon_resources_pb.Issue,
  /**
   * @param {!proto.devplay.coupon.internals.CreateIssueRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  devplay_coupon_internals_coupon_resources_pb.Issue.deserializeBinary
);


/**
 * @param {!proto.devplay.coupon.internals.CreateIssueRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devplay.coupon.internals.Issue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devplay.coupon.internals.Issue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devplay.coupon.internals.IssueInternalServiceClient.prototype.createIssue =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devplay.coupon.internals.IssueInternalService/CreateIssue',
      request,
      metadata || {},
      methodDescriptor_IssueInternalService_CreateIssue,
      callback);
};


/**
 * @param {!proto.devplay.coupon.internals.CreateIssueRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devplay.coupon.internals.Issue>}
 *     Promise that resolves to the response
 */
proto.devplay.coupon.internals.IssueInternalServicePromiseClient.prototype.createIssue =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devplay.coupon.internals.IssueInternalService/CreateIssue',
      request,
      metadata || {},
      methodDescriptor_IssueInternalService_CreateIssue);
};


module.exports = proto.devplay.coupon.internals;

