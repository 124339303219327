// source: pub-gamecenter/public/v1/resource.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.gamecenter.public.v1.AppIDConfig', null, global);
goog.exportSymbol('proto.gamecenter.public.v1.AppleIDPConfig', null, global);
goog.exportSymbol('proto.gamecenter.public.v1.AppstoreConfig', null, global);
goog.exportSymbol('proto.gamecenter.public.v1.ClientSDKConfig', null, global);
goog.exportSymbol('proto.gamecenter.public.v1.CouponConfig', null, global);
goog.exportSymbol('proto.gamecenter.public.v1.FacebookIDPConfig', null, global);
goog.exportSymbol('proto.gamecenter.public.v1.GalaxystoreConfig', null, global);
goog.exportSymbol('proto.gamecenter.public.v1.GameBasicInfo', null, global);
goog.exportSymbol('proto.gamecenter.public.v1.GameConfig', null, global);
goog.exportSymbol('proto.gamecenter.public.v1.GoogleAPIConfig', null, global);
goog.exportSymbol('proto.gamecenter.public.v1.GoogleIDPConfig', null, global);
goog.exportSymbol('proto.gamecenter.public.v1.OnestoreConfig', null, global);
goog.exportSymbol('proto.gamecenter.public.v1.PlaystoreConfig', null, global);
goog.exportSymbol('proto.gamecenter.public.v1.SteamConfig', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.public.v1.GameBasicInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.public.v1.GameBasicInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.public.v1.GameBasicInfo.displayName = 'proto.gamecenter.public.v1.GameBasicInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.public.v1.GameConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.public.v1.GameConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.public.v1.GameConfig.displayName = 'proto.gamecenter.public.v1.GameConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.public.v1.AppIDConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.public.v1.AppIDConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.public.v1.AppIDConfig.displayName = 'proto.gamecenter.public.v1.AppIDConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.public.v1.GoogleIDPConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.public.v1.GoogleIDPConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.public.v1.GoogleIDPConfig.displayName = 'proto.gamecenter.public.v1.GoogleIDPConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.public.v1.FacebookIDPConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.public.v1.FacebookIDPConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.public.v1.FacebookIDPConfig.displayName = 'proto.gamecenter.public.v1.FacebookIDPConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.public.v1.AppleIDPConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.public.v1.AppleIDPConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.public.v1.AppleIDPConfig.displayName = 'proto.gamecenter.public.v1.AppleIDPConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.public.v1.AppstoreConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.public.v1.AppstoreConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.public.v1.AppstoreConfig.displayName = 'proto.gamecenter.public.v1.AppstoreConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.public.v1.PlaystoreConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.public.v1.PlaystoreConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.public.v1.PlaystoreConfig.displayName = 'proto.gamecenter.public.v1.PlaystoreConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.public.v1.OnestoreConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.public.v1.OnestoreConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.public.v1.OnestoreConfig.displayName = 'proto.gamecenter.public.v1.OnestoreConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.public.v1.GalaxystoreConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.public.v1.GalaxystoreConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.public.v1.GalaxystoreConfig.displayName = 'proto.gamecenter.public.v1.GalaxystoreConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.public.v1.ClientSDKConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.public.v1.ClientSDKConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.public.v1.ClientSDKConfig.displayName = 'proto.gamecenter.public.v1.ClientSDKConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.public.v1.CouponConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.public.v1.CouponConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.public.v1.CouponConfig.displayName = 'proto.gamecenter.public.v1.CouponConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.public.v1.GoogleAPIConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.public.v1.GoogleAPIConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.public.v1.GoogleAPIConfig.displayName = 'proto.gamecenter.public.v1.GoogleAPIConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gamecenter.public.v1.SteamConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gamecenter.public.v1.SteamConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gamecenter.public.v1.SteamConfig.displayName = 'proto.gamecenter.public.v1.SteamConfig';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.public.v1.GameBasicInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.public.v1.GameBasicInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.public.v1.GameBasicInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.GameBasicInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    publicRelease: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    gameId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    loginTypesAllowed: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.public.v1.GameBasicInfo}
 */
proto.gamecenter.public.v1.GameBasicInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.public.v1.GameBasicInfo;
  return proto.gamecenter.public.v1.GameBasicInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.public.v1.GameBasicInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.public.v1.GameBasicInfo}
 */
proto.gamecenter.public.v1.GameBasicInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPublicRelease(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGameId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoginTypesAllowed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.public.v1.GameBasicInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.public.v1.GameBasicInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.public.v1.GameBasicInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.GameBasicInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPublicRelease();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getGameId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLoginTypesAllowed();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string game_code = 1;
 * @return {string}
 */
proto.gamecenter.public.v1.GameBasicInfo.prototype.getGameCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.GameBasicInfo} returns this
 */
proto.gamecenter.public.v1.GameBasicInfo.prototype.setGameCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.gamecenter.public.v1.GameBasicInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.GameBasicInfo} returns this
 */
proto.gamecenter.public.v1.GameBasicInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool public_release = 3;
 * @return {boolean}
 */
proto.gamecenter.public.v1.GameBasicInfo.prototype.getPublicRelease = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.gamecenter.public.v1.GameBasicInfo} returns this
 */
proto.gamecenter.public.v1.GameBasicInfo.prototype.setPublicRelease = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int32 game_id = 4;
 * @return {number}
 */
proto.gamecenter.public.v1.GameBasicInfo.prototype.getGameId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.gamecenter.public.v1.GameBasicInfo} returns this
 */
proto.gamecenter.public.v1.GameBasicInfo.prototype.setGameId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string login_types_allowed = 5;
 * @return {string}
 */
proto.gamecenter.public.v1.GameBasicInfo.prototype.getLoginTypesAllowed = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.GameBasicInfo} returns this
 */
proto.gamecenter.public.v1.GameBasicInfo.prototype.setLoginTypesAllowed = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.public.v1.GameConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.public.v1.GameConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.public.v1.GameConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.GameConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    appIdConfig: (f = msg.getAppIdConfig()) && proto.gamecenter.public.v1.AppIDConfig.toObject(includeInstance, f),
    googleIdpConfig: (f = msg.getGoogleIdpConfig()) && proto.gamecenter.public.v1.GoogleIDPConfig.toObject(includeInstance, f),
    facebookIdpConfig: (f = msg.getFacebookIdpConfig()) && proto.gamecenter.public.v1.FacebookIDPConfig.toObject(includeInstance, f),
    playstoreConfig: (f = msg.getPlaystoreConfig()) && proto.gamecenter.public.v1.PlaystoreConfig.toObject(includeInstance, f),
    clientSdkConfig: (f = msg.getClientSdkConfig()) && proto.gamecenter.public.v1.ClientSDKConfig.toObject(includeInstance, f),
    appleIdpConfig: (f = msg.getAppleIdpConfig()) && proto.gamecenter.public.v1.AppleIDPConfig.toObject(includeInstance, f),
    onestoreConfig: (f = msg.getOnestoreConfig()) && proto.gamecenter.public.v1.OnestoreConfig.toObject(includeInstance, f),
    appstoreConfig: (f = msg.getAppstoreConfig()) && proto.gamecenter.public.v1.AppstoreConfig.toObject(includeInstance, f),
    galaxystoreConfig: (f = msg.getGalaxystoreConfig()) && proto.gamecenter.public.v1.GalaxystoreConfig.toObject(includeInstance, f),
    couponConfig: (f = msg.getCouponConfig()) && proto.gamecenter.public.v1.CouponConfig.toObject(includeInstance, f),
    googleApiConfig: (f = msg.getGoogleApiConfig()) && proto.gamecenter.public.v1.GoogleAPIConfig.toObject(includeInstance, f),
    steamConfig: (f = msg.getSteamConfig()) && proto.gamecenter.public.v1.SteamConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.public.v1.GameConfig}
 */
proto.gamecenter.public.v1.GameConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.public.v1.GameConfig;
  return proto.gamecenter.public.v1.GameConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.public.v1.GameConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.public.v1.GameConfig}
 */
proto.gamecenter.public.v1.GameConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.gamecenter.public.v1.AppIDConfig;
      reader.readMessage(value,proto.gamecenter.public.v1.AppIDConfig.deserializeBinaryFromReader);
      msg.setAppIdConfig(value);
      break;
    case 2:
      var value = new proto.gamecenter.public.v1.GoogleIDPConfig;
      reader.readMessage(value,proto.gamecenter.public.v1.GoogleIDPConfig.deserializeBinaryFromReader);
      msg.setGoogleIdpConfig(value);
      break;
    case 3:
      var value = new proto.gamecenter.public.v1.FacebookIDPConfig;
      reader.readMessage(value,proto.gamecenter.public.v1.FacebookIDPConfig.deserializeBinaryFromReader);
      msg.setFacebookIdpConfig(value);
      break;
    case 4:
      var value = new proto.gamecenter.public.v1.PlaystoreConfig;
      reader.readMessage(value,proto.gamecenter.public.v1.PlaystoreConfig.deserializeBinaryFromReader);
      msg.setPlaystoreConfig(value);
      break;
    case 6:
      var value = new proto.gamecenter.public.v1.ClientSDKConfig;
      reader.readMessage(value,proto.gamecenter.public.v1.ClientSDKConfig.deserializeBinaryFromReader);
      msg.setClientSdkConfig(value);
      break;
    case 7:
      var value = new proto.gamecenter.public.v1.AppleIDPConfig;
      reader.readMessage(value,proto.gamecenter.public.v1.AppleIDPConfig.deserializeBinaryFromReader);
      msg.setAppleIdpConfig(value);
      break;
    case 8:
      var value = new proto.gamecenter.public.v1.OnestoreConfig;
      reader.readMessage(value,proto.gamecenter.public.v1.OnestoreConfig.deserializeBinaryFromReader);
      msg.setOnestoreConfig(value);
      break;
    case 9:
      var value = new proto.gamecenter.public.v1.AppstoreConfig;
      reader.readMessage(value,proto.gamecenter.public.v1.AppstoreConfig.deserializeBinaryFromReader);
      msg.setAppstoreConfig(value);
      break;
    case 10:
      var value = new proto.gamecenter.public.v1.GalaxystoreConfig;
      reader.readMessage(value,proto.gamecenter.public.v1.GalaxystoreConfig.deserializeBinaryFromReader);
      msg.setGalaxystoreConfig(value);
      break;
    case 11:
      var value = new proto.gamecenter.public.v1.CouponConfig;
      reader.readMessage(value,proto.gamecenter.public.v1.CouponConfig.deserializeBinaryFromReader);
      msg.setCouponConfig(value);
      break;
    case 12:
      var value = new proto.gamecenter.public.v1.GoogleAPIConfig;
      reader.readMessage(value,proto.gamecenter.public.v1.GoogleAPIConfig.deserializeBinaryFromReader);
      msg.setGoogleApiConfig(value);
      break;
    case 13:
      var value = new proto.gamecenter.public.v1.SteamConfig;
      reader.readMessage(value,proto.gamecenter.public.v1.SteamConfig.deserializeBinaryFromReader);
      msg.setSteamConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.public.v1.GameConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.public.v1.GameConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.public.v1.GameConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.GameConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppIdConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.gamecenter.public.v1.AppIDConfig.serializeBinaryToWriter
    );
  }
  f = message.getGoogleIdpConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.gamecenter.public.v1.GoogleIDPConfig.serializeBinaryToWriter
    );
  }
  f = message.getFacebookIdpConfig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.gamecenter.public.v1.FacebookIDPConfig.serializeBinaryToWriter
    );
  }
  f = message.getPlaystoreConfig();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.gamecenter.public.v1.PlaystoreConfig.serializeBinaryToWriter
    );
  }
  f = message.getClientSdkConfig();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.gamecenter.public.v1.ClientSDKConfig.serializeBinaryToWriter
    );
  }
  f = message.getAppleIdpConfig();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.gamecenter.public.v1.AppleIDPConfig.serializeBinaryToWriter
    );
  }
  f = message.getOnestoreConfig();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.gamecenter.public.v1.OnestoreConfig.serializeBinaryToWriter
    );
  }
  f = message.getAppstoreConfig();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.gamecenter.public.v1.AppstoreConfig.serializeBinaryToWriter
    );
  }
  f = message.getGalaxystoreConfig();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.gamecenter.public.v1.GalaxystoreConfig.serializeBinaryToWriter
    );
  }
  f = message.getCouponConfig();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.gamecenter.public.v1.CouponConfig.serializeBinaryToWriter
    );
  }
  f = message.getGoogleApiConfig();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.gamecenter.public.v1.GoogleAPIConfig.serializeBinaryToWriter
    );
  }
  f = message.getSteamConfig();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.gamecenter.public.v1.SteamConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional AppIDConfig app_id_config = 1;
 * @return {?proto.gamecenter.public.v1.AppIDConfig}
 */
proto.gamecenter.public.v1.GameConfig.prototype.getAppIdConfig = function() {
  return /** @type{?proto.gamecenter.public.v1.AppIDConfig} */ (
    jspb.Message.getWrapperField(this, proto.gamecenter.public.v1.AppIDConfig, 1));
};


/**
 * @param {?proto.gamecenter.public.v1.AppIDConfig|undefined} value
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
*/
proto.gamecenter.public.v1.GameConfig.prototype.setAppIdConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
 */
proto.gamecenter.public.v1.GameConfig.prototype.clearAppIdConfig = function() {
  return this.setAppIdConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gamecenter.public.v1.GameConfig.prototype.hasAppIdConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GoogleIDPConfig google_idp_config = 2;
 * @return {?proto.gamecenter.public.v1.GoogleIDPConfig}
 */
proto.gamecenter.public.v1.GameConfig.prototype.getGoogleIdpConfig = function() {
  return /** @type{?proto.gamecenter.public.v1.GoogleIDPConfig} */ (
    jspb.Message.getWrapperField(this, proto.gamecenter.public.v1.GoogleIDPConfig, 2));
};


/**
 * @param {?proto.gamecenter.public.v1.GoogleIDPConfig|undefined} value
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
*/
proto.gamecenter.public.v1.GameConfig.prototype.setGoogleIdpConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
 */
proto.gamecenter.public.v1.GameConfig.prototype.clearGoogleIdpConfig = function() {
  return this.setGoogleIdpConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gamecenter.public.v1.GameConfig.prototype.hasGoogleIdpConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FacebookIDPConfig facebook_idp_config = 3;
 * @return {?proto.gamecenter.public.v1.FacebookIDPConfig}
 */
proto.gamecenter.public.v1.GameConfig.prototype.getFacebookIdpConfig = function() {
  return /** @type{?proto.gamecenter.public.v1.FacebookIDPConfig} */ (
    jspb.Message.getWrapperField(this, proto.gamecenter.public.v1.FacebookIDPConfig, 3));
};


/**
 * @param {?proto.gamecenter.public.v1.FacebookIDPConfig|undefined} value
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
*/
proto.gamecenter.public.v1.GameConfig.prototype.setFacebookIdpConfig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
 */
proto.gamecenter.public.v1.GameConfig.prototype.clearFacebookIdpConfig = function() {
  return this.setFacebookIdpConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gamecenter.public.v1.GameConfig.prototype.hasFacebookIdpConfig = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PlaystoreConfig playstore_config = 4;
 * @return {?proto.gamecenter.public.v1.PlaystoreConfig}
 */
proto.gamecenter.public.v1.GameConfig.prototype.getPlaystoreConfig = function() {
  return /** @type{?proto.gamecenter.public.v1.PlaystoreConfig} */ (
    jspb.Message.getWrapperField(this, proto.gamecenter.public.v1.PlaystoreConfig, 4));
};


/**
 * @param {?proto.gamecenter.public.v1.PlaystoreConfig|undefined} value
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
*/
proto.gamecenter.public.v1.GameConfig.prototype.setPlaystoreConfig = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
 */
proto.gamecenter.public.v1.GameConfig.prototype.clearPlaystoreConfig = function() {
  return this.setPlaystoreConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gamecenter.public.v1.GameConfig.prototype.hasPlaystoreConfig = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ClientSDKConfig client_sdk_config = 6;
 * @return {?proto.gamecenter.public.v1.ClientSDKConfig}
 */
proto.gamecenter.public.v1.GameConfig.prototype.getClientSdkConfig = function() {
  return /** @type{?proto.gamecenter.public.v1.ClientSDKConfig} */ (
    jspb.Message.getWrapperField(this, proto.gamecenter.public.v1.ClientSDKConfig, 6));
};


/**
 * @param {?proto.gamecenter.public.v1.ClientSDKConfig|undefined} value
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
*/
proto.gamecenter.public.v1.GameConfig.prototype.setClientSdkConfig = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
 */
proto.gamecenter.public.v1.GameConfig.prototype.clearClientSdkConfig = function() {
  return this.setClientSdkConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gamecenter.public.v1.GameConfig.prototype.hasClientSdkConfig = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional AppleIDPConfig apple_idp_config = 7;
 * @return {?proto.gamecenter.public.v1.AppleIDPConfig}
 */
proto.gamecenter.public.v1.GameConfig.prototype.getAppleIdpConfig = function() {
  return /** @type{?proto.gamecenter.public.v1.AppleIDPConfig} */ (
    jspb.Message.getWrapperField(this, proto.gamecenter.public.v1.AppleIDPConfig, 7));
};


/**
 * @param {?proto.gamecenter.public.v1.AppleIDPConfig|undefined} value
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
*/
proto.gamecenter.public.v1.GameConfig.prototype.setAppleIdpConfig = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
 */
proto.gamecenter.public.v1.GameConfig.prototype.clearAppleIdpConfig = function() {
  return this.setAppleIdpConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gamecenter.public.v1.GameConfig.prototype.hasAppleIdpConfig = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional OnestoreConfig onestore_config = 8;
 * @return {?proto.gamecenter.public.v1.OnestoreConfig}
 */
proto.gamecenter.public.v1.GameConfig.prototype.getOnestoreConfig = function() {
  return /** @type{?proto.gamecenter.public.v1.OnestoreConfig} */ (
    jspb.Message.getWrapperField(this, proto.gamecenter.public.v1.OnestoreConfig, 8));
};


/**
 * @param {?proto.gamecenter.public.v1.OnestoreConfig|undefined} value
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
*/
proto.gamecenter.public.v1.GameConfig.prototype.setOnestoreConfig = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
 */
proto.gamecenter.public.v1.GameConfig.prototype.clearOnestoreConfig = function() {
  return this.setOnestoreConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gamecenter.public.v1.GameConfig.prototype.hasOnestoreConfig = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional AppstoreConfig appstore_config = 9;
 * @return {?proto.gamecenter.public.v1.AppstoreConfig}
 */
proto.gamecenter.public.v1.GameConfig.prototype.getAppstoreConfig = function() {
  return /** @type{?proto.gamecenter.public.v1.AppstoreConfig} */ (
    jspb.Message.getWrapperField(this, proto.gamecenter.public.v1.AppstoreConfig, 9));
};


/**
 * @param {?proto.gamecenter.public.v1.AppstoreConfig|undefined} value
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
*/
proto.gamecenter.public.v1.GameConfig.prototype.setAppstoreConfig = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
 */
proto.gamecenter.public.v1.GameConfig.prototype.clearAppstoreConfig = function() {
  return this.setAppstoreConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gamecenter.public.v1.GameConfig.prototype.hasAppstoreConfig = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional GalaxystoreConfig galaxystore_config = 10;
 * @return {?proto.gamecenter.public.v1.GalaxystoreConfig}
 */
proto.gamecenter.public.v1.GameConfig.prototype.getGalaxystoreConfig = function() {
  return /** @type{?proto.gamecenter.public.v1.GalaxystoreConfig} */ (
    jspb.Message.getWrapperField(this, proto.gamecenter.public.v1.GalaxystoreConfig, 10));
};


/**
 * @param {?proto.gamecenter.public.v1.GalaxystoreConfig|undefined} value
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
*/
proto.gamecenter.public.v1.GameConfig.prototype.setGalaxystoreConfig = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
 */
proto.gamecenter.public.v1.GameConfig.prototype.clearGalaxystoreConfig = function() {
  return this.setGalaxystoreConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gamecenter.public.v1.GameConfig.prototype.hasGalaxystoreConfig = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional CouponConfig coupon_config = 11;
 * @return {?proto.gamecenter.public.v1.CouponConfig}
 */
proto.gamecenter.public.v1.GameConfig.prototype.getCouponConfig = function() {
  return /** @type{?proto.gamecenter.public.v1.CouponConfig} */ (
    jspb.Message.getWrapperField(this, proto.gamecenter.public.v1.CouponConfig, 11));
};


/**
 * @param {?proto.gamecenter.public.v1.CouponConfig|undefined} value
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
*/
proto.gamecenter.public.v1.GameConfig.prototype.setCouponConfig = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
 */
proto.gamecenter.public.v1.GameConfig.prototype.clearCouponConfig = function() {
  return this.setCouponConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gamecenter.public.v1.GameConfig.prototype.hasCouponConfig = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional GoogleAPIConfig google_api_config = 12;
 * @return {?proto.gamecenter.public.v1.GoogleAPIConfig}
 */
proto.gamecenter.public.v1.GameConfig.prototype.getGoogleApiConfig = function() {
  return /** @type{?proto.gamecenter.public.v1.GoogleAPIConfig} */ (
    jspb.Message.getWrapperField(this, proto.gamecenter.public.v1.GoogleAPIConfig, 12));
};


/**
 * @param {?proto.gamecenter.public.v1.GoogleAPIConfig|undefined} value
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
*/
proto.gamecenter.public.v1.GameConfig.prototype.setGoogleApiConfig = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
 */
proto.gamecenter.public.v1.GameConfig.prototype.clearGoogleApiConfig = function() {
  return this.setGoogleApiConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gamecenter.public.v1.GameConfig.prototype.hasGoogleApiConfig = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional SteamConfig steam_config = 13;
 * @return {?proto.gamecenter.public.v1.SteamConfig}
 */
proto.gamecenter.public.v1.GameConfig.prototype.getSteamConfig = function() {
  return /** @type{?proto.gamecenter.public.v1.SteamConfig} */ (
    jspb.Message.getWrapperField(this, proto.gamecenter.public.v1.SteamConfig, 13));
};


/**
 * @param {?proto.gamecenter.public.v1.SteamConfig|undefined} value
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
*/
proto.gamecenter.public.v1.GameConfig.prototype.setSteamConfig = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gamecenter.public.v1.GameConfig} returns this
 */
proto.gamecenter.public.v1.GameConfig.prototype.clearSteamConfig = function() {
  return this.setSteamConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gamecenter.public.v1.GameConfig.prototype.hasSteamConfig = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.public.v1.AppIDConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.public.v1.AppIDConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.public.v1.AppIDConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.AppIDConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    appstoreBundleId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    playstoreBundleId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    onestoreBundleId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    galaxystoreBundleId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    steamBundleId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    playstationBundleId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.public.v1.AppIDConfig}
 */
proto.gamecenter.public.v1.AppIDConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.public.v1.AppIDConfig;
  return proto.gamecenter.public.v1.AppIDConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.public.v1.AppIDConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.public.v1.AppIDConfig}
 */
proto.gamecenter.public.v1.AppIDConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppstoreBundleId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaystoreBundleId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnestoreBundleId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setGalaxystoreBundleId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSteamBundleId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaystationBundleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.public.v1.AppIDConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.public.v1.AppIDConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.public.v1.AppIDConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.AppIDConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppstoreBundleId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPlaystoreBundleId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOnestoreBundleId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getGalaxystoreBundleId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSteamBundleId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPlaystationBundleId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string appstore_bundle_id = 3;
 * @return {string}
 */
proto.gamecenter.public.v1.AppIDConfig.prototype.getAppstoreBundleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.AppIDConfig} returns this
 */
proto.gamecenter.public.v1.AppIDConfig.prototype.setAppstoreBundleId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string playstore_bundle_id = 4;
 * @return {string}
 */
proto.gamecenter.public.v1.AppIDConfig.prototype.getPlaystoreBundleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.AppIDConfig} returns this
 */
proto.gamecenter.public.v1.AppIDConfig.prototype.setPlaystoreBundleId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string onestore_bundle_id = 5;
 * @return {string}
 */
proto.gamecenter.public.v1.AppIDConfig.prototype.getOnestoreBundleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.AppIDConfig} returns this
 */
proto.gamecenter.public.v1.AppIDConfig.prototype.setOnestoreBundleId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string galaxystore_bundle_id = 6;
 * @return {string}
 */
proto.gamecenter.public.v1.AppIDConfig.prototype.getGalaxystoreBundleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.AppIDConfig} returns this
 */
proto.gamecenter.public.v1.AppIDConfig.prototype.setGalaxystoreBundleId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string steam_bundle_id = 7;
 * @return {string}
 */
proto.gamecenter.public.v1.AppIDConfig.prototype.getSteamBundleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.AppIDConfig} returns this
 */
proto.gamecenter.public.v1.AppIDConfig.prototype.setSteamBundleId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string playstation_bundle_id = 8;
 * @return {string}
 */
proto.gamecenter.public.v1.AppIDConfig.prototype.getPlaystationBundleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.AppIDConfig} returns this
 */
proto.gamecenter.public.v1.AppIDConfig.prototype.setPlaystationBundleId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.public.v1.GoogleIDPConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.public.v1.GoogleIDPConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.public.v1.GoogleIDPConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.GoogleIDPConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientSecret: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.public.v1.GoogleIDPConfig}
 */
proto.gamecenter.public.v1.GoogleIDPConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.public.v1.GoogleIDPConfig;
  return proto.gamecenter.public.v1.GoogleIDPConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.public.v1.GoogleIDPConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.public.v1.GoogleIDPConfig}
 */
proto.gamecenter.public.v1.GoogleIDPConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientSecret(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.public.v1.GoogleIDPConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.public.v1.GoogleIDPConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.public.v1.GoogleIDPConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.GoogleIDPConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientSecret();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string client_id = 1;
 * @return {string}
 */
proto.gamecenter.public.v1.GoogleIDPConfig.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.GoogleIDPConfig} returns this
 */
proto.gamecenter.public.v1.GoogleIDPConfig.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_secret = 2;
 * @return {string}
 */
proto.gamecenter.public.v1.GoogleIDPConfig.prototype.getClientSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.GoogleIDPConfig} returns this
 */
proto.gamecenter.public.v1.GoogleIDPConfig.prototype.setClientSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.public.v1.FacebookIDPConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.public.v1.FacebookIDPConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.public.v1.FacebookIDPConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.FacebookIDPConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    appSecret: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.public.v1.FacebookIDPConfig}
 */
proto.gamecenter.public.v1.FacebookIDPConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.public.v1.FacebookIDPConfig;
  return proto.gamecenter.public.v1.FacebookIDPConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.public.v1.FacebookIDPConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.public.v1.FacebookIDPConfig}
 */
proto.gamecenter.public.v1.FacebookIDPConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppSecret(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.public.v1.FacebookIDPConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.public.v1.FacebookIDPConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.public.v1.FacebookIDPConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.FacebookIDPConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAppSecret();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.gamecenter.public.v1.FacebookIDPConfig.prototype.getAppId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.FacebookIDPConfig} returns this
 */
proto.gamecenter.public.v1.FacebookIDPConfig.prototype.setAppId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string app_secret = 2;
 * @return {string}
 */
proto.gamecenter.public.v1.FacebookIDPConfig.prototype.getAppSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.FacebookIDPConfig} returns this
 */
proto.gamecenter.public.v1.FacebookIDPConfig.prototype.setAppSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.public.v1.AppleIDPConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.public.v1.AppleIDPConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.public.v1.AppleIDPConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.AppleIDPConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    serviceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deepLink: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.public.v1.AppleIDPConfig}
 */
proto.gamecenter.public.v1.AppleIDPConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.public.v1.AppleIDPConfig;
  return proto.gamecenter.public.v1.AppleIDPConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.public.v1.AppleIDPConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.public.v1.AppleIDPConfig}
 */
proto.gamecenter.public.v1.AppleIDPConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeepLink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.public.v1.AppleIDPConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.public.v1.AppleIDPConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.public.v1.AppleIDPConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.AppleIDPConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServiceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeepLink();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string client_id = 1;
 * @return {string}
 */
proto.gamecenter.public.v1.AppleIDPConfig.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.AppleIDPConfig} returns this
 */
proto.gamecenter.public.v1.AppleIDPConfig.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string service_id = 2;
 * @return {string}
 */
proto.gamecenter.public.v1.AppleIDPConfig.prototype.getServiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.AppleIDPConfig} returns this
 */
proto.gamecenter.public.v1.AppleIDPConfig.prototype.setServiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deep_link = 3;
 * @return {string}
 */
proto.gamecenter.public.v1.AppleIDPConfig.prototype.getDeepLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.AppleIDPConfig} returns this
 */
proto.gamecenter.public.v1.AppleIDPConfig.prototype.setDeepLink = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.public.v1.AppstoreConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.public.v1.AppstoreConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.public.v1.AppstoreConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.AppstoreConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    appSpecificSharedSecret: jspb.Message.getFieldWithDefault(msg, 1, ""),
    privateKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    keyId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    issuerId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.public.v1.AppstoreConfig}
 */
proto.gamecenter.public.v1.AppstoreConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.public.v1.AppstoreConfig;
  return proto.gamecenter.public.v1.AppstoreConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.public.v1.AppstoreConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.public.v1.AppstoreConfig}
 */
proto.gamecenter.public.v1.AppstoreConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppSpecificSharedSecret(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivateKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssuerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.public.v1.AppstoreConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.public.v1.AppstoreConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.public.v1.AppstoreConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.AppstoreConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppSpecificSharedSecret();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrivateKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKeyId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIssuerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string app_specific_shared_secret = 1;
 * @return {string}
 */
proto.gamecenter.public.v1.AppstoreConfig.prototype.getAppSpecificSharedSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.AppstoreConfig} returns this
 */
proto.gamecenter.public.v1.AppstoreConfig.prototype.setAppSpecificSharedSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string private_key = 2;
 * @return {string}
 */
proto.gamecenter.public.v1.AppstoreConfig.prototype.getPrivateKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.AppstoreConfig} returns this
 */
proto.gamecenter.public.v1.AppstoreConfig.prototype.setPrivateKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string key_id = 3;
 * @return {string}
 */
proto.gamecenter.public.v1.AppstoreConfig.prototype.getKeyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.AppstoreConfig} returns this
 */
proto.gamecenter.public.v1.AppstoreConfig.prototype.setKeyId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string issuer_id = 4;
 * @return {string}
 */
proto.gamecenter.public.v1.AppstoreConfig.prototype.getIssuerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.AppstoreConfig} returns this
 */
proto.gamecenter.public.v1.AppstoreConfig.prototype.setIssuerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.public.v1.PlaystoreConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.public.v1.PlaystoreConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.public.v1.PlaystoreConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.PlaystoreConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    iabPubKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    serviceAccountJson: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.public.v1.PlaystoreConfig}
 */
proto.gamecenter.public.v1.PlaystoreConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.public.v1.PlaystoreConfig;
  return proto.gamecenter.public.v1.PlaystoreConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.public.v1.PlaystoreConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.public.v1.PlaystoreConfig}
 */
proto.gamecenter.public.v1.PlaystoreConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIabPubKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceAccountJson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.public.v1.PlaystoreConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.public.v1.PlaystoreConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.public.v1.PlaystoreConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.PlaystoreConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIabPubKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServiceAccountJson();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string iab_pub_key = 1;
 * @return {string}
 */
proto.gamecenter.public.v1.PlaystoreConfig.prototype.getIabPubKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.PlaystoreConfig} returns this
 */
proto.gamecenter.public.v1.PlaystoreConfig.prototype.setIabPubKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string service_account_json = 2;
 * @return {string}
 */
proto.gamecenter.public.v1.PlaystoreConfig.prototype.getServiceAccountJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.PlaystoreConfig} returns this
 */
proto.gamecenter.public.v1.PlaystoreConfig.prototype.setServiceAccountJson = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.public.v1.OnestoreConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.public.v1.OnestoreConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.public.v1.OnestoreConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.OnestoreConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    iapPubKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientSecret: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.public.v1.OnestoreConfig}
 */
proto.gamecenter.public.v1.OnestoreConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.public.v1.OnestoreConfig;
  return proto.gamecenter.public.v1.OnestoreConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.public.v1.OnestoreConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.public.v1.OnestoreConfig}
 */
proto.gamecenter.public.v1.OnestoreConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIapPubKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientSecret(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.public.v1.OnestoreConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.public.v1.OnestoreConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.public.v1.OnestoreConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.OnestoreConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIapPubKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientSecret();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string iap_pub_key = 1;
 * @return {string}
 */
proto.gamecenter.public.v1.OnestoreConfig.prototype.getIapPubKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.OnestoreConfig} returns this
 */
proto.gamecenter.public.v1.OnestoreConfig.prototype.setIapPubKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_secret = 2;
 * @return {string}
 */
proto.gamecenter.public.v1.OnestoreConfig.prototype.getClientSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.OnestoreConfig} returns this
 */
proto.gamecenter.public.v1.OnestoreConfig.prototype.setClientSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.public.v1.GalaxystoreConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.public.v1.GalaxystoreConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.public.v1.GalaxystoreConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.GalaxystoreConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientSecret: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.public.v1.GalaxystoreConfig}
 */
proto.gamecenter.public.v1.GalaxystoreConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.public.v1.GalaxystoreConfig;
  return proto.gamecenter.public.v1.GalaxystoreConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.public.v1.GalaxystoreConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.public.v1.GalaxystoreConfig}
 */
proto.gamecenter.public.v1.GalaxystoreConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientSecret(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.public.v1.GalaxystoreConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.public.v1.GalaxystoreConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.public.v1.GalaxystoreConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.GalaxystoreConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientSecret();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string client_secret = 1;
 * @return {string}
 */
proto.gamecenter.public.v1.GalaxystoreConfig.prototype.getClientSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.GalaxystoreConfig} returns this
 */
proto.gamecenter.public.v1.GalaxystoreConfig.prototype.setClientSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.public.v1.ClientSDKConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.public.v1.ClientSDKConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.public.v1.ClientSDKConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.ClientSDKConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    heartbeatInterval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    appstoreDownloadUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    playstoreDownloadUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    onestoreDownloadUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    galaxystoreDownloadUrl: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.public.v1.ClientSDKConfig}
 */
proto.gamecenter.public.v1.ClientSDKConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.public.v1.ClientSDKConfig;
  return proto.gamecenter.public.v1.ClientSDKConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.public.v1.ClientSDKConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.public.v1.ClientSDKConfig}
 */
proto.gamecenter.public.v1.ClientSDKConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHeartbeatInterval(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppstoreDownloadUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaystoreDownloadUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnestoreDownloadUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setGalaxystoreDownloadUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.public.v1.ClientSDKConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.public.v1.ClientSDKConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.public.v1.ClientSDKConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.ClientSDKConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeartbeatInterval();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAppstoreDownloadUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPlaystoreDownloadUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOnestoreDownloadUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getGalaxystoreDownloadUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 heartbeat_interval = 1;
 * @return {number}
 */
proto.gamecenter.public.v1.ClientSDKConfig.prototype.getHeartbeatInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.gamecenter.public.v1.ClientSDKConfig} returns this
 */
proto.gamecenter.public.v1.ClientSDKConfig.prototype.setHeartbeatInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string appstore_download_url = 4;
 * @return {string}
 */
proto.gamecenter.public.v1.ClientSDKConfig.prototype.getAppstoreDownloadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.ClientSDKConfig} returns this
 */
proto.gamecenter.public.v1.ClientSDKConfig.prototype.setAppstoreDownloadUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string playstore_download_url = 5;
 * @return {string}
 */
proto.gamecenter.public.v1.ClientSDKConfig.prototype.getPlaystoreDownloadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.ClientSDKConfig} returns this
 */
proto.gamecenter.public.v1.ClientSDKConfig.prototype.setPlaystoreDownloadUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string onestore_download_url = 6;
 * @return {string}
 */
proto.gamecenter.public.v1.ClientSDKConfig.prototype.getOnestoreDownloadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.ClientSDKConfig} returns this
 */
proto.gamecenter.public.v1.ClientSDKConfig.prototype.setOnestoreDownloadUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string galaxystore_download_url = 7;
 * @return {string}
 */
proto.gamecenter.public.v1.ClientSDKConfig.prototype.getGalaxystoreDownloadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.ClientSDKConfig} returns this
 */
proto.gamecenter.public.v1.ClientSDKConfig.prototype.setGalaxystoreDownloadUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.public.v1.CouponConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.public.v1.CouponConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.public.v1.CouponConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.CouponConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    webEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    webBaseUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.public.v1.CouponConfig}
 */
proto.gamecenter.public.v1.CouponConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.public.v1.CouponConfig;
  return proto.gamecenter.public.v1.CouponConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.public.v1.CouponConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.public.v1.CouponConfig}
 */
proto.gamecenter.public.v1.CouponConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWebEnabled(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebBaseUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.public.v1.CouponConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.public.v1.CouponConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.public.v1.CouponConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.CouponConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWebEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getWebBaseUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool web_enabled = 1;
 * @return {boolean}
 */
proto.gamecenter.public.v1.CouponConfig.prototype.getWebEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.gamecenter.public.v1.CouponConfig} returns this
 */
proto.gamecenter.public.v1.CouponConfig.prototype.setWebEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string web_base_url = 2;
 * @return {string}
 */
proto.gamecenter.public.v1.CouponConfig.prototype.getWebBaseUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.CouponConfig} returns this
 */
proto.gamecenter.public.v1.CouponConfig.prototype.setWebBaseUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.public.v1.GoogleAPIConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.public.v1.GoogleAPIConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.public.v1.GoogleAPIConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.GoogleAPIConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceAccountJson: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.public.v1.GoogleAPIConfig}
 */
proto.gamecenter.public.v1.GoogleAPIConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.public.v1.GoogleAPIConfig;
  return proto.gamecenter.public.v1.GoogleAPIConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.public.v1.GoogleAPIConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.public.v1.GoogleAPIConfig}
 */
proto.gamecenter.public.v1.GoogleAPIConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceAccountJson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.public.v1.GoogleAPIConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.public.v1.GoogleAPIConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.public.v1.GoogleAPIConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.GoogleAPIConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceAccountJson();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string service_account_json = 1;
 * @return {string}
 */
proto.gamecenter.public.v1.GoogleAPIConfig.prototype.getServiceAccountJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.GoogleAPIConfig} returns this
 */
proto.gamecenter.public.v1.GoogleAPIConfig.prototype.setServiceAccountJson = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gamecenter.public.v1.SteamConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.gamecenter.public.v1.SteamConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gamecenter.public.v1.SteamConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.SteamConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    appId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    webApiKey: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gamecenter.public.v1.SteamConfig}
 */
proto.gamecenter.public.v1.SteamConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gamecenter.public.v1.SteamConfig;
  return proto.gamecenter.public.v1.SteamConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gamecenter.public.v1.SteamConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gamecenter.public.v1.SteamConfig}
 */
proto.gamecenter.public.v1.SteamConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAppId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebApiKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gamecenter.public.v1.SteamConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gamecenter.public.v1.SteamConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gamecenter.public.v1.SteamConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gamecenter.public.v1.SteamConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getWebApiKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 app_id = 1;
 * @return {number}
 */
proto.gamecenter.public.v1.SteamConfig.prototype.getAppId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.gamecenter.public.v1.SteamConfig} returns this
 */
proto.gamecenter.public.v1.SteamConfig.prototype.setAppId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string web_api_key = 2;
 * @return {string}
 */
proto.gamecenter.public.v1.SteamConfig.prototype.getWebApiKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.gamecenter.public.v1.SteamConfig} returns this
 */
proto.gamecenter.public.v1.SteamConfig.prototype.setWebApiKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.gamecenter.public.v1);
