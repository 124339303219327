/**
 * @fileoverview gRPC-Web generated client stub for devplay.coupon.internals
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.29.1
// source: devplay/coupon/internals/event.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var devplay_coupon_internals_coupon_resources_pb = require('../../../devplay/coupon/internals/coupon_resources_pb.js')
const proto = {};
proto.devplay = {};
proto.devplay.coupon = {};
proto.devplay.coupon.internals = require('./event_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.devplay.coupon.internals.EventInternalServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.devplay.coupon.internals.EventInternalServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devplay.coupon.internals.SearchEventsRequest,
 *   !proto.devplay.coupon.internals.SearchEventsResponse>}
 */
const methodDescriptor_EventInternalService_SearchEvents = new grpc.web.MethodDescriptor(
  '/devplay.coupon.internals.EventInternalService/SearchEvents',
  grpc.web.MethodType.UNARY,
  proto.devplay.coupon.internals.SearchEventsRequest,
  proto.devplay.coupon.internals.SearchEventsResponse,
  /**
   * @param {!proto.devplay.coupon.internals.SearchEventsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devplay.coupon.internals.SearchEventsResponse.deserializeBinary
);


/**
 * @param {!proto.devplay.coupon.internals.SearchEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devplay.coupon.internals.SearchEventsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devplay.coupon.internals.SearchEventsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devplay.coupon.internals.EventInternalServiceClient.prototype.searchEvents =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devplay.coupon.internals.EventInternalService/SearchEvents',
      request,
      metadata || {},
      methodDescriptor_EventInternalService_SearchEvents,
      callback);
};


/**
 * @param {!proto.devplay.coupon.internals.SearchEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devplay.coupon.internals.SearchEventsResponse>}
 *     Promise that resolves to the response
 */
proto.devplay.coupon.internals.EventInternalServicePromiseClient.prototype.searchEvents =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devplay.coupon.internals.EventInternalService/SearchEvents',
      request,
      metadata || {},
      methodDescriptor_EventInternalService_SearchEvents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devplay.coupon.internals.GetEventRequest,
 *   !proto.devplay.coupon.internals.Event>}
 */
const methodDescriptor_EventInternalService_GetEvent = new grpc.web.MethodDescriptor(
  '/devplay.coupon.internals.EventInternalService/GetEvent',
  grpc.web.MethodType.UNARY,
  proto.devplay.coupon.internals.GetEventRequest,
  devplay_coupon_internals_coupon_resources_pb.Event,
  /**
   * @param {!proto.devplay.coupon.internals.GetEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  devplay_coupon_internals_coupon_resources_pb.Event.deserializeBinary
);


/**
 * @param {!proto.devplay.coupon.internals.GetEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devplay.coupon.internals.Event)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devplay.coupon.internals.Event>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devplay.coupon.internals.EventInternalServiceClient.prototype.getEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devplay.coupon.internals.EventInternalService/GetEvent',
      request,
      metadata || {},
      methodDescriptor_EventInternalService_GetEvent,
      callback);
};


/**
 * @param {!proto.devplay.coupon.internals.GetEventRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devplay.coupon.internals.Event>}
 *     Promise that resolves to the response
 */
proto.devplay.coupon.internals.EventInternalServicePromiseClient.prototype.getEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devplay.coupon.internals.EventInternalService/GetEvent',
      request,
      metadata || {},
      methodDescriptor_EventInternalService_GetEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest,
 *   !proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse>}
 */
const methodDescriptor_EventInternalService_GetRandomTypeEventUsage = new grpc.web.MethodDescriptor(
  '/devplay.coupon.internals.EventInternalService/GetRandomTypeEventUsage',
  grpc.web.MethodType.UNARY,
  proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest,
  proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse,
  /**
   * @param {!proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse.deserializeBinary
);


/**
 * @param {!proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devplay.coupon.internals.EventInternalServiceClient.prototype.getRandomTypeEventUsage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devplay.coupon.internals.EventInternalService/GetRandomTypeEventUsage',
      request,
      metadata || {},
      methodDescriptor_EventInternalService_GetRandomTypeEventUsage,
      callback);
};


/**
 * @param {!proto.devplay.coupon.internals.GetRandomTypeEventUsageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devplay.coupon.internals.GetRandomTypeEventUsageResponse>}
 *     Promise that resolves to the response
 */
proto.devplay.coupon.internals.EventInternalServicePromiseClient.prototype.getRandomTypeEventUsage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devplay.coupon.internals.EventInternalService/GetRandomTypeEventUsage',
      request,
      metadata || {},
      methodDescriptor_EventInternalService_GetRandomTypeEventUsage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devplay.coupon.internals.GetWordTypeEventUsageRequest,
 *   !proto.devplay.coupon.internals.GetWordTypeEventUsageResponse>}
 */
const methodDescriptor_EventInternalService_GetWordTypeEventUsage = new grpc.web.MethodDescriptor(
  '/devplay.coupon.internals.EventInternalService/GetWordTypeEventUsage',
  grpc.web.MethodType.UNARY,
  proto.devplay.coupon.internals.GetWordTypeEventUsageRequest,
  proto.devplay.coupon.internals.GetWordTypeEventUsageResponse,
  /**
   * @param {!proto.devplay.coupon.internals.GetWordTypeEventUsageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devplay.coupon.internals.GetWordTypeEventUsageResponse.deserializeBinary
);


/**
 * @param {!proto.devplay.coupon.internals.GetWordTypeEventUsageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devplay.coupon.internals.GetWordTypeEventUsageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devplay.coupon.internals.GetWordTypeEventUsageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devplay.coupon.internals.EventInternalServiceClient.prototype.getWordTypeEventUsage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devplay.coupon.internals.EventInternalService/GetWordTypeEventUsage',
      request,
      metadata || {},
      methodDescriptor_EventInternalService_GetWordTypeEventUsage,
      callback);
};


/**
 * @param {!proto.devplay.coupon.internals.GetWordTypeEventUsageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devplay.coupon.internals.GetWordTypeEventUsageResponse>}
 *     Promise that resolves to the response
 */
proto.devplay.coupon.internals.EventInternalServicePromiseClient.prototype.getWordTypeEventUsage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devplay.coupon.internals.EventInternalService/GetWordTypeEventUsage',
      request,
      metadata || {},
      methodDescriptor_EventInternalService_GetWordTypeEventUsage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devplay.coupon.internals.CreateEventRequest,
 *   !proto.devplay.coupon.internals.Event>}
 */
const methodDescriptor_EventInternalService_CreateEvent = new grpc.web.MethodDescriptor(
  '/devplay.coupon.internals.EventInternalService/CreateEvent',
  grpc.web.MethodType.UNARY,
  proto.devplay.coupon.internals.CreateEventRequest,
  devplay_coupon_internals_coupon_resources_pb.Event,
  /**
   * @param {!proto.devplay.coupon.internals.CreateEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  devplay_coupon_internals_coupon_resources_pb.Event.deserializeBinary
);


/**
 * @param {!proto.devplay.coupon.internals.CreateEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devplay.coupon.internals.Event)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devplay.coupon.internals.Event>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devplay.coupon.internals.EventInternalServiceClient.prototype.createEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devplay.coupon.internals.EventInternalService/CreateEvent',
      request,
      metadata || {},
      methodDescriptor_EventInternalService_CreateEvent,
      callback);
};


/**
 * @param {!proto.devplay.coupon.internals.CreateEventRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devplay.coupon.internals.Event>}
 *     Promise that resolves to the response
 */
proto.devplay.coupon.internals.EventInternalServicePromiseClient.prototype.createEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devplay.coupon.internals.EventInternalService/CreateEvent',
      request,
      metadata || {},
      methodDescriptor_EventInternalService_CreateEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devplay.coupon.internals.UpdateEventRequest,
 *   !proto.devplay.coupon.internals.Event>}
 */
const methodDescriptor_EventInternalService_UpdateEvent = new grpc.web.MethodDescriptor(
  '/devplay.coupon.internals.EventInternalService/UpdateEvent',
  grpc.web.MethodType.UNARY,
  proto.devplay.coupon.internals.UpdateEventRequest,
  devplay_coupon_internals_coupon_resources_pb.Event,
  /**
   * @param {!proto.devplay.coupon.internals.UpdateEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  devplay_coupon_internals_coupon_resources_pb.Event.deserializeBinary
);


/**
 * @param {!proto.devplay.coupon.internals.UpdateEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devplay.coupon.internals.Event)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devplay.coupon.internals.Event>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devplay.coupon.internals.EventInternalServiceClient.prototype.updateEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devplay.coupon.internals.EventInternalService/UpdateEvent',
      request,
      metadata || {},
      methodDescriptor_EventInternalService_UpdateEvent,
      callback);
};


/**
 * @param {!proto.devplay.coupon.internals.UpdateEventRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devplay.coupon.internals.Event>}
 *     Promise that resolves to the response
 */
proto.devplay.coupon.internals.EventInternalServicePromiseClient.prototype.updateEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devplay.coupon.internals.EventInternalService/UpdateEvent',
      request,
      metadata || {},
      methodDescriptor_EventInternalService_UpdateEvent);
};


module.exports = proto.devplay.coupon.internals;

